import styled from 'styled-components'

export const ScrollerContainer = styled.div`
  margin: 0 -15px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: content-box;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ScrollList = styled.div`
  padding-left: 7.5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  > * {
    flex-shrink: 0;
    width: 80%;
  }

  & > div:last-child {
    width: 7.5px;
  }
`

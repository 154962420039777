import React from 'react'
import styled from 'styled-components'
import { compose, withHandlers } from 'recompose'

import { mobileFirstMedia as media } from 'utils/media'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import Card from 'components/Card/Card'
import Carousel from 'pages/homepage/Carousel'
import DesktopContainer from 'layouts/DesktopContainer/index'
import events from 'const/analytics-events'
import IconV2 from 'components.v2/Icon/IconV2'
import testimonials from 'const/testimonials'
import Text from 'components.v2/Text/Text'

const Testimonials = ({ onChange }) =>
  !(testimonials || testimonials.length) ? null : (
    <DesktopContainer
      color="peachLight"
      className="px-15 xl:px-0 pt-30 pb-50 xl:pt-60 xl:pb-80"
    >
      <Text variant="h2" className="text-left xl:text-center">
        What people are saying
      </Text>
      <Carousel className="mt-30 xl:mt-50" onChange={onChange}>
        {testimonials.map((t, i) => (
          <CardContainer className="xl:h-full px-7pt5 xl:px-15" key={i}>
            <Card className="h-full p-20 xl:p-30">
              <div className="text-center">
                <QuoteIcon />
              </div>
              <Text align="center" className="pt-15">
                {t.message}
              </Text>
              <Text align="center" className="pt-20" bold>
                — {t.author}
              </Text>
            </Card>
          </CardContainer>
        ))}
      </Carousel>
    </DesktopContainer>
  )

export default compose(
  withAnalyticsHandler,
  withHandlers({
    onChange: ({ trackEvent }) => ({ dir }) => {
      if (dir === 'NEXT') {
        trackEvent(events['TESTIMONIAL_CLICK_RIGHT'])
      } else {
        trackEvent(events['TESTIMONIAL_CLICK_LEFT'])
      }
    },
  }),
)(Testimonials)

const CardContainer = styled.div`
  width: 285px;

  ${media.screen`
    width: auto;
  `}
`

const QuoteIcon = styled(IconV2).attrs({ src: 'Quote.svg' })`
  font-size: 27px;
  color: ${p => p.theme.colors.peachDark};
`

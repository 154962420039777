export default [
  {
    message:
      '…very good and super convenient…works really well. The menu system is really nicely laid out and user friendly. It remembers your frequent orders so you can reorder quickly.',
    author: 'Wynn C.',
  },
  {
    message:
      'Definitely beat the Q!! …Ordered just before I left work for my break. Got to the shop and picked up my coffee. What else could you possibly ask for when time is limited on break.',
    author: 'Ramsey K.',
  },
  {
    message:
      'Convenient, quick, no chance of a misheard order or forgetting to ask for ‘extra hot’ … You can also check out what is available at the cafe … on the train/bus, work out what you want to order…',
    author: 'Shelley S.',
  },
  {
    message:
      '…wanted to grab coffee at Circa … the wait time can get insane … So I tried out the app - incredibly user friendly and I had no trouble at all understanding how to navigate around…',
    author: 'Ella K.',
  },
  {
    message:
      'If you are short on time and want to grab a quick bite , this is the best app, just order your food while you are still at office … can save you 15-20 mins during peak times',
    author: 'Ben W.',
  },
  {
    message:
      '…saves me so much hassle as I have a small baby - just click and order and it’s ready when the notification pops up … I choose to go to vendors who use this app simply for the convenience.',
    author: 'Kyle M.',
  },
]

import React from 'react'
import PT from 'prop-types'
import LazyLoad from 'react-lazyload'
import {
  Section,
  SliderTitle,
  SliderItemContent,
  SliderContentText,
  AppStoreContent,
  SlideContainer,
  SliderImage,
  AppStoreImage,
} from './styled'

import contents from './contentSlides'
import asset from 'utils/asset'

class ContentSection extends React.Component {
  static defaultProps = {
    contents,
  }

  static propTypes = {
    contents: PT.arrayOf(
      PT.shape({
        index: PT.number,
        title: PT.string,
        content: PT.string,
        image: PT.string,
        icon: PT.string,
      }),
    ).isRequired,
  }

  contentSlider = React.createRef()

  imageSlider = React.createRef()

  state = {
    active: 0,
  }

  onChangeSlide = ({ newIndex }) => {
    this.setState({ active: newIndex })
  }

  handleControlClick = currentIndex => {
    return () => {
      this.imageSlider.current.slideAndRestart(currentIndex)
      this.contentSlider.current.slideAndRestart(currentIndex)
      this.setState({ currentIndex })
    }
  }

  render() {
    return (
      <Section>
        {this.props.contents.map((item, i) => {
          return (
            <SliderItemContent key={i}>
              <SlideContainer className={item.image || 'no-image'}>
                <SliderTitle>{item.title}</SliderTitle>
                <SliderContentText>{item.content}</SliderContentText>
                <AppStoreContent>
                  <a href="https://apps.apple.com/au/app/hey-you-beat-the-queue/id480619522">
                    <LazyLoad>
                      <AppStoreImage
                        src={asset('images/app-store-btn-1.jpg')}
                        alt="images/app-store-btn-1.jpg"
                      />
                    </LazyLoad>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.beattheq.android&hl=en">
                    <LazyLoad>
                      <AppStoreImage
                        src={asset('images/play-store-btn-1.jpg')}
                        alt="images/play-store-btn-1.jpg"
                      />
                    </LazyLoad>
                  </a>
                </AppStoreContent>
              </SlideContainer>
              {item.image && (
                <SliderImage src={asset(item.image)} alt={item.image} />
              )}
            </SliderItemContent>
          )
        })}
      </Section>
    )
  }
}

export default ContentSection

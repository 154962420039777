import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const InnerContainer = styled.div`
  margin: 0 -17.5px;

  & .slick-track {
    display: flex;
    align-items: stretch;
  }

  & .slick-track > .slick-slide {
    height: auto !important;
  }

  & .slick-track > .slick-slide > div {
    height: 100%;
    line-height: 0;
  }
`

const Button = styled(({ buttonOffset, ...props }) => <button {...props} />)`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: ${p => (p.buttonOffset ? `${p.buttonOffset}px` : '50%')};
  outline: none;
  border: 0;
  height: 50px;
  width: 50px;
  transform: ${p => (p.buttonOffset ? 'none' : 'translateY(-50%)')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: ${props => props.theme.colors.white};
`

export const LeftButton = styled(Button)`
  left: 0;
  transform: translate(-50%, -50%);
`

export const RightButton = styled(Button)`
  right: 0;
  transform: translate(50%, -50%);
`

import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import qs from 'qs'
import { show, types } from 'store/modals'

export default compose(
  connect(
    null,
    { show },
  ),

  lifecycle({
    componentDidMount() {
      const { location, show } = this.props
      const referralCode = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).referralcode

      if (referralCode) show(types.REGISTER)
    },
  }),
)

import styled from 'styled-components'

export const Card = styled.section`
  padding: 30px 15px 20px;
  background-color: ${props => props.theme.colors.white};
`

export const TitleSection = styled.div`
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ViewAll = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`

export const ContentContainer = styled.div``

export const DesktopTitle = styled.div`
  padding-bottom: 20px;
  border-bottom: 2px solid ${p => p.theme.colors.gray5};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DesktopContent = styled.div`
  margin-top: 30px;
`

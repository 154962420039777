import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import isNode from 'detect-node'

import desktopFirst, { mobileFirstMedia as media } from 'utils/media'

function LazyImage({ actualSrc, viewType, className, ...props }) {
  if (isNode) {
    return (
      <Fragment>
        {viewType === 'mobile' ? (
          <HeroOverlay className={className} actualSrc={actualSrc} {...props} />
        ) : (
          <HeroBackground actualSrc={actualSrc} {...props} />
        )}
      </Fragment>
    )
  }

  const [isImageLoaded, setImageLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => setImageLoaded(true)
    img.onerror = () => setHasError(true)

    img.src = actualSrc
  }, [actualSrc])

  return (
    <Fragment>
      {viewType === 'mobile' ? (
        <HeroOverlay
          actualSrc={actualSrc}
          loading={!isImageLoaded}
          error={hasError}
          className={className}
          {...props}
        />
      ) : (
        <HeroBackground
          actualSrc={actualSrc}
          loading={!isImageLoaded}
          error={hasError}
          {...props}
        />
      )}
    </Fragment>
  )
}

const HeroBackground = styled.div`
  width: 100%;
  position: relative;
  ${media.screen`
    background-image: ${props =>
      props.error
        ? 'none'
        : props.loading
        ? 'none'
        : `url(${props.actualSrc})`};
    background-position-x: 45%;
    background-position-y: 0px;
    background-repeat: no-repeat;
    background-size: cover;
  `}
`

export const HeroOverlay = styled.div`
  background-image: ${props =>
    props.error ? 'none' : props.loading ? 'none' : `url(${props.actualSrc})`};
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  width: 100%;
  &.full {
    width: 100%;
    height: 100%;
    ${desktopFirst.screen` height: 100%; !important`}
    ${desktopFirst.tablet` height: 100%; !important`}
    ${desktopFirst.phone` height: 100%; !important`}
    ${media.screen` display: none; `};
  }
  
  &:not(.full) {
    ${desktopFirst.phone` height: 215px !important;`};
    ${desktopFirst.tablet` height: 250px;`};
    ${desktopFirst.screen` height: 250px;`};
    ${media.screen` display: none; `};
  }
  
`
export default LazyImage

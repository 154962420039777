import React from 'react'
import { arrayOf, node, number } from 'prop-types'

import Icon from 'components/Icon'

import Slider from 'react-slick'

import { Container, LeftButton, RightButton, InnerContainer } from './styled'

const SPEED = 500

class Carousel extends React.Component {
  state = {
    page: 0,
    isTransacting: false,
  }

  static propTypes = {
    children: arrayOf(node).isRequired,
    buttonOffset: number,
    onChange: () => {},
  }

  handleNext = () => {
    if (this.state.isTransacting) return

    const newPage = this.state.page + 1

    this.setState({
      isTransacting: true,
      page: newPage,
    })

    this.props.onChange({
      dir: 'NEXT',
      newPage,
    })

    this.slider.slickNext()
    setTimeout(() => this.resetTransaction(), SPEED)
  }

  handlePrev = () => {
    if (this.state.isTransacting) return

    const newPage = this.state.page - 1

    this.setState({
      isTransacting: true,
      page: newPage,
    })

    this.props.onChange({
      dir: 'PREV',
      newPage,
    })

    this.slider.slickPrev()
    setTimeout(() => this.resetTransaction(), SPEED)
  }

  resetTransaction = () => {
    if (this.container) {
      this.setState({
        isTransacting: false,
      })
    }
  }

  render() {
    const { children, buttonOffset, ...props } = this.props
    const { page } = this.state
    const maxPage = Math.ceil(children.length / 3)
    const settings = {
      draggable: false,
      arrows: false,
      dots: false,
      infinite: false,
      speed: SPEED,
      slidesToShow: 3,
      slidesToScroll: 3,
    }

    return (
      <Container ref={ref => (this.container = ref)} {...props}>
        {children.length > 3 && page > 0 && (
          <LeftButton buttonOffset={buttonOffset} onClick={this.handlePrev}>
            <Icon
              name="fas fa-arrow-left"
              color="orange"
              size={16}
              height={50}
            />
          </LeftButton>
        )}
        <InnerContainer>
          <Slider ref={ref => (this.slider = ref)} {...settings}>
            {children}
          </Slider>
        </InnerContainer>
        {children.length > 3 && page < maxPage - 1 && (
          <RightButton buttonOffset={buttonOffset} onClick={this.handleNext}>
            <Icon
              name="fas fa-arrow-right"
              color="orange"
              size={16}
              height={50}
            />
          </RightButton>
        )}
      </Container>
    )
  }
}

export default Carousel

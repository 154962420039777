import React, { Fragment } from 'react'
import ButtonCarousel from './ButtonCarousel'
import HorizontalScroller from './HorizontalScroller'
import { DesktopScreen, MobileScreen } from 'utils/screen'

export default ({ children, ...props }) => (
  <Fragment>
    <DesktopScreen>
      <ButtonCarousel {...props}>{children}</ButtonCarousel>
    </DesktopScreen>
    <MobileScreen>
      <HorizontalScroller {...props}>{children}</HorizontalScroller>
    </MobileScreen>
  </Fragment>
)

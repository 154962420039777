export default [
  {
    index: 3,
    title: 'Download Australia’s favourite order ahead app',
    titleMobile: 'Party with friends',
    content:
      'Order from the best cafes and restaurants across Australia for breakfast, lunch and dinner through the Hey You app',
    image: 'images/phone-img-2.webp',
    icon: 'images/icons/party.svg',
    iconWidth: '81px',
    download: true,
  },
]

import React from 'react'
import PT from 'prop-types'
import times from 'lodash/times'
import isEmpty from 'lodash/isEmpty'

import {
  VenueCardContainer,
  ListPanelContainer,
  EmptyContainer,
} from './styled'
import { SectionsContainer } from '../styled'
import Carousel from 'pages/homepage/Carousel'
import ListPanel from 'pages/homepage/ListPanel'
import VenueCard, { VenueCardLoader } from 'common/VenueCard'
import events from 'const/analytics-events'
import withAnalytics, { withAnalyticsHandler } from 'enhancers/withAnalytics'

const VenueCardEnhanced = withAnalytics()(VenueCard)

class HomeVenuesSection extends React.Component {
  handleChangeCarousel = listType => props => {
    if (listType === 'POPULAR' && props.dir === 'NEXT') {
      this.props.trackEvent(events['POPULAR_CLICK_RIGHT'])
    } else if (listType === 'POPULAR' && props.dir === 'PREV') {
      this.props.trackEvent(events['POPULAR_CLICK_LEFT'])
    } else if (listType === 'DISCOUNTED' && props.dir === 'NEXT') {
      this.props.trackEvent(events['WHATS_HAPPENING_CLICK_RIGHT'])
    } else if (listType === 'DISCOUNTED' && props.dir === 'PREV') {
      this.props.trackEvent(events['WHATS_HAPPENING_CLICK_LEFT'])
    }
  }

  renderItems = () => {
    const { venues, listType } = this.props
    if (Array.isArray(venues) && venues.length) {
      return venues.map(venue => (
        <VenueCardContainer key={venue.id}>
          <VenueCardEnhanced
            {...venue}
            analytics={[
              {
                eventType: 'onClick',
                options: {
                  ...events[
                    listType === 'POPULAR'
                      ? 'POPULAR_CLICK_VENUE'
                      : 'WHATS_HAPPENING_CLICK_VENUE'
                  ],
                  label: String(venue.id),
                },
              },
            ]}
          />
        </VenueCardContainer>
      ))
    }
    return venuesLoaderJsx
  }

  render() {
    const { title, action, listType, venues } = this.props
    return Array.isArray(venues) && !isEmpty(venues) ? (
      <SectionsContainer>
        <ListPanelContainer>
          <ListPanel title={title} action={action}>
            <Carousel
              buttonOffset={100}
              onChange={this.handleChangeCarousel(listType)}
            >
              {this.renderItems()}
            </Carousel>
          </ListPanel>
        </ListPanelContainer>
      </SectionsContainer>
    ) : (
      <EmptyContainer />
    )
  }
}

HomeVenuesSection.propTypes = {
  action: PT.func,
  title: PT.string.isRequired,
  venues: PT.arrayOf(
    PT.shape({
      id: PT.number.isRequired,
    }),
  ),
}

const venuesLoaderJsx = times(4, i => (
  <VenueCardContainer key={i}>
    <VenueCardLoader />
  </VenueCardContainer>
))

export default withAnalyticsHandler(HomeVenuesSection)

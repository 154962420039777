import { mobileFirstMedia as media } from 'utils/media'
import styled from 'styled-components'

export const SectionsContainer = styled.div`
  background-color: #fcfcfc;
  padding-bottom: 20px;

  ${media.screen`
    padding-bottom: 60px;
  `}
`

export const SectionContainer = styled.div`
  padding-top: 20px;

  ${media.screen`
    padding-top: 60px;
  `}
`

import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import asset from 'utils/asset'

import Text from 'components.v2/Text'
import desktopFirst, { mobileFirstMedia as media } from 'utils/media'

export const HeroSectionWrapper = styled.div`
  font-family: 'Montserrat', sans-serif !important;
  width: 100%;
  background-color: ${colors['gray7']};
  display: flex;
  flex-direction: column;
`

export const HeroSectionWrapperSecond = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
`

export const HeroNoBackground = styled.div`
  width: 100%;
  position: relative;
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 468px;

  ${media.screen`
    width: 528px;
  `};
`

export const HeroTitle = styled(props => (
  <Text variant="h1" color="black" {...props} />
))`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 30px 0px 20px 0px;
  padding: 0 30px 0 30px;
  text-align: center;

  ${media.tablet`
    width: 70%;
    margin: 30px auto 20px auto;
    font-size: 25px;
    line-height: 35px;
  `};

  ${media.screen`
    width: 100%;
    font-size: 46px;
    line-height: 60px;  
    margin: 80px 0px 30px 0px;
    text-align: left;
    padding: 0;
  `};
`

export const HeroSearch = styled.div`
  flex: 1;
  margin: auto;
  position: relative;

  ${media.screen`
    margin: 0;
    background-image: none;
  `};
`

export const HeroCities = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  background: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;

  a {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-right: 36px !important;
  }

  ${media.screen`
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    position: relative;

    a { 
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;  
    }
  `};
`

export const HeroLocation = styled.div`
  margin-bottom: 30px;

  ${media.screen`
    margin-bottom: 0;
  `};

  > div {
    margin: auto;

    ${media.screen`
      margin: 0;
    `};
  }

  input {
    font-size: 16px !important;
  }
  button > span > p {
    font-size: 13px !important;
  }

  ${media.screen`
    input, button > span > p {
      font-size: 14px !important;
    }
  `};
`

export const HeroOverlay = styled.div`
  background-image: url('${asset(
    'images/heyyou-desktop-banner-main-small.webp',
  )}');
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  width: 100%;

  ${desktopFirst.phone` height: 215px !important;`};
  ${desktopFirst.tablet`height: 250px;`};
  ${desktopFirst.screen` height: 250px;`};
  ${media.screen` display: none; `};
`

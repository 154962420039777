import React from 'react'
import { connect } from 'react-redux'
import { selectCity, set as setCity } from 'store/homepage/city'
import CitySelector from 'common/CitySelector'

const withCityReduxState = connect(
  state => ({ city: selectCity(state) }),
  { setCity },
)

export default withCityReduxState(({ city, setCity }) => {
  return <CitySelector city={city} onSelectCity={setCity} />
})

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { DesktopScreen, MobileScreen } from 'utils/screen'
import DesktopContainer from 'layouts/DesktopContainer'
import Text from 'components.v2/Text'
import Icon from 'components/Icon'

import {
  Card,
  TitleSection,
  ViewAll,
  ContentContainer,
  DesktopTitle,
  DesktopContent,
} from './styled'

class ListSection extends React.Component {
  render() {
    const { title, action, link, children } = this.props
    const actionProps = link
      ? { as: Link, to: link }
      : action
      ? { href: '#', onClick: action }
      : null

    return (
      <Fragment>
        <MobileScreen>
          <Card>
            <TitleSection>
              <Text variant="h3">{title}</Text>
              {actionProps && (
                <ViewAll {...actionProps}>
                  <Text color="secondary" bold>
                    View all
                  </Text>
                  <Icon
                    color="secondary"
                    width={20}
                    height={20}
                    size={8}
                    name="fas fa-chevron-right"
                  />
                </ViewAll>
              )}
            </TitleSection>
            <ContentContainer>{children}</ContentContainer>
          </Card>
        </MobileScreen>

        <DesktopScreen>
          <DesktopContainer>
            <DesktopTitle>
              <Text variant="h3">{title}</Text>
              {actionProps && (
                <ViewAll {...actionProps}>
                  <Text color="secondary" bold>
                    View all
                  </Text>
                  <Icon
                    color="secondary"
                    width={20}
                    height={20}
                    size={8}
                    name="fas fa-chevron-right"
                  />
                </ViewAll>
              )}
            </DesktopTitle>
            <DesktopContent>{children}</DesktopContent>
          </DesktopContainer>
        </DesktopScreen>
      </Fragment>
    )
  }
}

ListSection.propTypes = {
  action: PropTypes.func,
  link: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  expandAction: PropTypes.func,
}

export default ListSection

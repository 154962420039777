import styled from 'styled-components'
import media from 'utils/media'

export const VenueCardContainer = styled.div`
  padding: 0 17.5px;
  width: 230px;

  ${media.screen`
    padding: 0 7.5px;
    width: 300px;
  `}
`

export const EmptyContainer = styled.div`
  margin-bottom: 60px;

  ${media.screen`
    margin-bottom: 20px;
  `}
`

export const ListPanelContainer = styled.div`
  padding-top: 55px;

  ${media.screen`
    padding-top: 20px;
  `}
`

export const CitySelectorInner = styled.div`
  margin: 0 auto;
  max-width: 1180px;

  ${media.screen`
    padding: 0 15px;
  `}
`

export const CitySelectorOuter = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #ffffff;

  ${media.screen`
    border-bottom: none;
  `}
`

import { withProps, compose, lifecycle, branch } from 'recompose'
import isNode from 'detect-node'
import { connect } from 'react-redux'

import { fetchAds, getAds } from 'store/homepage/ads'

export default compose(
  connect(
    state => ({ venues: getAds(state) }),
    { fetchAds },
  ),

  // render nothing if no discounted venues
  branch(p => !(p.venues && p.venues.length), () => () => null),

  withProps({
    title: 'What’s happening',
    hasDiscount: true,
    listType: 'DISCOUNTED',
  }),

  lifecycle({
    bootstrap() {
      return this.props.fetchAds()
    },

    componentDidMount() {
      if (!isNode && !this.props.venues.length) this.props.fetchAds()
    },
  }),
)

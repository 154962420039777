import React from 'react'
import styled from 'styled-components'

import { mobileFirstMedia as media } from 'utils/media'
import colors from 'theme/colors'
import asset from 'utils/asset'
import Text from 'components.v2/Text'

export const Wrapper = styled.div`
  background-color: ${colors['peachLight']};
  min-height: 450px;
  overflow: hidden;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${media.screen`
    flex-direction: row;
    justify-content: stretch;
    height: 675px;
  `};
`
export const SectionLeft = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;

  ${media.screen`
    align-items: center;
    width: 50%;
  `};
`

export const SectionLeftInner = styled.div`
  height: 100%;
  ${media.screen`
    padding-top: 0;
    width: 100%;
  `};
`

export const SectionRight = styled.div`
  display: flex;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  align-items: center;
  justify-content: stretch;
  margin: auto;
  overflow: hidden;

  ${media.screen`
    width: 50%;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
  `};
`

export const SectionRightMobile = styled.div`
  flex: 1;
  display: flex;
  align-items: bottom;
  justify-content: center;
`

export const SectionRightMobileWrapper = styled.div`
  width: 320px;
  height: 360px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  ${media.screen`
    width: 400px;
    height: 450px;
  `};
`

export const PhoneMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position-y: 15px;
  background-position-x: center;
  background-image: url(${asset('images/phone-mask.png')});
  background-repeat: no-repeat;
  z-index: 2;

  ${media.screen`
    background-position-x: center;
    background-position-y: bottom;
  `};
`

export const SectionRightControls = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SliderImageWrapper = styled.div`
  text-align: center;
  margin: auto;
  overflow: hidden;
  width: 215px;
  padding-top: 67px;

  ${media.screen`
    padding-top: 65px;
    width: 278px;
  `};
`

export const SliderImage = styled.img`
  width: 299px;
  width: 313px;
  ${media.screen`
    width: 574px;
    height: 601px;
  `};
`

export const SliderItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 40px;

  ${media.screen`
    flex-direction: row;
    height: 675px;
    gap: 0px;
  `}
`

export const SliderIcon = styled.img`
  margin-bottom: 25px;
  width: ${props => props.width};
  height: auto;
`

export const SliderTitle = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #333333;

  width: 315px;

  ${media.screen`
    font-weight: 500;
    font-size: 50px;
    line-height: 60px;
    width: 100%;
  `}
`

export const ContentPadding = styled.div`
  padding: 44px 25px 39px 25px;
  background-color: white;
  &.peach {
    background-color: ${colors['peachLight']};
  }
  a {
    font-weight: 500;
  }
`

export const SliderContent = styled(props => (
  <Text variant="body" color="black" {...props} />
))`
  margin-bottom: 10px;
  margin-top: 30px;
  word-wrap: break-word;

  ${media.screen`
    width: 100%;
    margin-top: 0;
  `};
`

export const SubContent = styled(props => (
  <Text variant="h4" color="black" {...props} />
))`
  margin: 0;
  font-weight: 300;

  &.last {
    margin-bottom: 30px;
  }
`

export const FormLabel = styled(props => (
  <Text variant="tiny" color="black" {...props} />
))`
  margin-bottom: 15px;
`

export const Form = styled.form`
  margin-top: 30px;
  width: 100%;
  ${media.screen`
    width: 95%;
  `};
`

export const Controls = styled.div`
  overflow-wrap: break-word;
`

export const ControlsItem = styled.span`
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: transparent;
  display: inline-block;
  background-color: ${props =>
    props.active ? colors['secondary'] : 'rgba(51, 51, 51, 0.1)'};
  border-radius: 50%;
`

export const HiddenMessage = styled.span`
  color: transparent;
`

export const SliderContentText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  width: 281px;
  color: #333333;

  ${media.screen`
    font-size: 16px;
    line-height: 25px;
    width: 100%;
  `}
`
export const AppStoreContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;

  ${media.screen`
      align-items: normal;
      text-align: left;
      gap: 14px;
      &.no-image {
        align-items: center;
        text-align: center;
        gap: 16px;
        width: 580px;
      }
    `}
`

export const AppStoreImage = styled.img`
  width: 134.34px;
  ${media.screen`
      height: auto;
      width: auto;
    `}
`

import React from 'react'

import {
  HeroSectionWrapper,
  HeroContent,
  HeroTitle,
  HeroSearch,
  HeroCities,
  HeroLocation,
  HeroNoBackground,
} from './styled'
import DesktopContainer from 'layouts/DesktopContainer'
import LocationSearchBar from 'common/LocationSearchBar'
import CitySelector from '../CitySelector'
import { MobileScreen, DesktopScreen } from 'utils/screen'
import LazyImage from 'pages/homepage/HeroSection/LazyImage'
import asset from 'utils/asset'

const HeroContents = ({ children }) => {
  return (
    <HeroContent>
      <HeroSearch>
        <HeroTitle>
          Your favourite local cafés and restaurants at your fingertips.
        </HeroTitle>
        <HeroLocation className="px-15 xl:px-0">
          <LocationSearchBar
            placement="hero"
            defaultBorderStyle="solid 1px white"
            cta
          />
        </HeroLocation>
        {children}
      </HeroSearch>
      <HeroCities>
        <CitySelector />
      </HeroCities>
    </HeroContent>
  )
}

const HeroMobile = () => {
  return (
    <MobileScreen>
      <HeroNoBackground>
        <HeroContents>
          <LazyImage
            actualSrc={asset('images/heyyou-desktop-banner-main-small.webp')}
            viewType="mobile"
          />
        </HeroContents>
      </HeroNoBackground>
    </MobileScreen>
  )
}

const HeroDesktop = () => {
  return (
    <DesktopScreen>
      <LazyImage actualSrc={asset('images/heyyou-desktop-banner-main.webp')}>
        <HeroContents />
      </LazyImage>
    </DesktopScreen>
  )
}

const MainSlideV2 = () => {
  return (
    <HeroSectionWrapper>
      <DesktopContainer>
        <HeroMobile />
        <HeroDesktop />
      </DesktopContainer>
    </HeroSectionWrapper>
  )
}

export default MainSlideV2

import React from 'react'
import styled from 'styled-components'
import colors from 'theme/colors'
import { mobileFirstMedia as media } from 'utils/media'
import Text from 'components.v2/Text'
import Button from 'components.v2/Button'

export const QrDineinSectionWrapper = styled.div`
  font-family: 'Montserrat', sans-serif !important;
  width: 100%;
  background-color: ${colors['gray8']};
  display: flex;
  flex-direction: column;
`

export const QrDineinContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 468px;
  align-items: center;

  ${media.screen`
    width: 610px;
    align-items: flex-start;
  `};
`

export const QrDineinTitle = styled(props => (
  <Text variant="h1" color={colors['orange']} {...props} />
))`
  font-size: 28px;
  line-height: 35.2px;
  font-weight: 800;
  margin: 42px auto 10px;
  padding: 0 30px 0 30px;
  text-align: center;

  ${media.tablet`
    width: 70%;
    font-size: 24px;
    line-height: normal;
  `};

  ${media.screen`
    width: 100%;
    font-size: 50px;
    line-height: 60px;    
    margin: 128px 0px 14px 0px;
    text-align: left;
    padding: 0;
  `};
`

export const QrDineinFlow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 260px;
  text-align: left;

  ${media.screen`
    max-width: 100%;
    width: 100%;
    text-align: center;
  `}
`

export const QrDineinFlowItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  svg {
    margin-top: 3px;
  }

  ${media.screen`
    margin-top: 20px;
    margin: 0 28px 0 0;
    
    &:last-child {
      margin-right: 0;
    }
  `}
`

export const QrDineinFlowTitle = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: ${colors['secondary']};
  margin-left: 5px;

  ${media.screen`
    font-size: 20px;
    line-height: 32px;
  `}
`

export const QrDineinLink = styled.a`
  max-width: 155px;
  width: 100%;
  margin-top: 22px;
  text-align: center;
  font-size: 12px;

  ${media.screen`
    max-width: 100%;
  `}
`

export const QrDineinButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  max-width: 320px;
  width: 100%;
  position: relative;

  ${media.screen`
    font-size: 14px;
    max-width: 320px;
  `}
`

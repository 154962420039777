import React from 'react'
import PropTypes from 'prop-types'

import { ScrollerContainer, ScrollList } from './styled'

const BOUNCE_TIME = 500

class HorizontalScroller extends React.Component {
  state = { width: 0, isScrolling: false, time: null }

  handleScroll = () => {
    if (this.state.isScrolling) return

    const itemWidth = this.state.width * 0.8
    let velocity = itemWidth / BOUNCE_TIME

    const actives = Math.round(this.container.scrollLeft / itemWidth)
    let left = Math.round(itemWidth * (actives - 0.1))

    if (actives === 0) left = 0

    if (this.container.scrollLeft > left) velocity = -velocity

    const diff = Math.abs((this.container.scrollLeft - left) / velocity)

    this.setState({
      velocity,
      time: new Date().getTime(),
      toTime: new Date().getTime() + diff,
      toScroll: left,
      isScrolling: true,
    })

    this.animate = window.requestAnimationFrame(this.animation)
  }

  animation = () => {
    const { velocity, time, toTime } = this.state

    const now = new Date().getTime()

    if (now > toTime) {
      window.cancelAnimationFrame(this.animate)
      return this.setState({
        isScrolling: false,
      })
    }

    const left = (now - time) * velocity
    this.container.scrollLeft += left
    this.setState({
      time: now,
    })
    this.animate = window.requestAnimationFrame(this.animation)
  }

  endScroll = () => {
    const { toScroll } = this.state
    this.container.scrollLeft = toScroll
    window.cancelAnimationFrame(this.animate)
    this.setState({
      isScrolling: false,
    })
  }

  refresh = () => {
    this.setState({
      width: this.container.offsetWidth,
    })
  }

  render() {
    const { children, ...props } = this.props

    return (
      <ScrollerContainer ref={ref => (this.container = ref)} {...props}>
        <ScrollList ref={ref => (this.list = ref)}>
          {children}
          <div /> {/* Filler div for padding */}
        </ScrollList>
      </ScrollerContainer>
    )
  }
}

HorizontalScroller.propTypes = {
  children: PropTypes.node,
}

export default HorizontalScroller

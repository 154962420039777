import React, { Fragment } from 'react'
import CityVenuesList from './CityVenuesList'
import DiscountedVenuesList from './DiscountedVenuesList'

export default () => (
  <Fragment>
    <CityVenuesList />
    <DiscountedVenuesList />
  </Fragment>
)

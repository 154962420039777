import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import MainSlideV2 from './MainSlideV2/MainSlideV2'
import AllYouNeedSlide from './AllYouNeedSlide/AllYouNeedSlide'
import QrDineinSlide from './QrDineinSlide/QrDineinSlide'
import CustomAds from './CustomAds'
import { compose } from 'recompose'
import { createSelector } from 'reselect'
import { getHeroAds } from 'store/homepage/ads'
import { connect } from 'react-redux'
import map from 'lodash/map'
import get from 'lodash/get'
import isNode from 'detect-node'

const SliderWrapper = styled.div`
  overflow: hidden;
`

const SliderDots = styled.div`
  position: relative;
  top: -35px;

  li {
    button:before {
      font-size: 15px;
      opacity: 0.3;
      color: #f26722;
    }
  }

  .slick-active {
    button:before {
      opacity: 1;
      color: #f26722 !important;
    }
  }
`

const slides = [
  {
    priority: 0,
    component: <MainSlideV2 />,
  },
  {
    priority: 0,
    component: <AllYouNeedSlide />,
  },
  {
    priority: 0,
    component: <QrDineinSlide />,
  },
]

const HeroCarousel = ({ ads }) => {
  const adsContent = map(ads, (ad, index) => ({
    priority: ad.priority,
    component: <CustomAds key={index} {...ad} />,
  }))

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    appendDots: dots => (
      <SliderDots>
        <ul>{dots}</ul>
      </SliderDots>
    ),
  }

  if (isNode)
    return (
      <SliderWrapper>
        <Slider {...settings}>{adsContent.map(ads => ads.component)}</Slider>
      </SliderWrapper>
    )

  let sliderRef = useRef(null)

  const sortAds = (a, b) => (get(a, 'priority') ? -1 : 1)

  useEffect(() => {
    if (ads.length) sliderRef.slickGoTo(0)
  }, [ads])

  return (
    <SliderWrapper>
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        {[...slides, ...adsContent].sort(sortAds).map(ads => ads.component)}
      </Slider>
    </SliderWrapper>
  )
}

export default compose(
  connect(
    createSelector(getHeroAds, ads => ({ ads })),
    null,
  ),
)(HeroCarousel)

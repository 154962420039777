import React from 'react'
import { SliderTitle, SliderIcon, ContentPadding, SubContent } from './styled'

import LINKS from 'const/links'
import Button from 'components.v2/Button'

import contents from './contentSlides'
import asset from 'utils/asset'

function MobileValueProp() {
  return contents.map((item, i) => {
    return (
      <ContentPadding
        key={String(i)}
        className={item.index % 2 === 0 && 'peach'}
      >
        <SliderIcon src={asset(item.icon)} width={item.iconWidth} />
        <SliderTitle>{item.titleMobile}</SliderTitle>
        <SubContent className={item.download && 'last'}>
          {item.content}
        </SubContent>
        {item.download && (
          <Button
            as="a"
            href={LINKS.APP_DOWNLOAD_IOS}
            rel="noopener"
            target="_blank"
            variant="primary"
            bold
          >
            Download our app
          </Button>
        )}
      </ContentPadding>
    )
  })
}

export default MobileValueProp

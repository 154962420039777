import styled from 'styled-components'

import { mobileFirstMedia as media } from 'utils/media'
import desktopFirst from 'utils/media'

export const HeroSectionWrapper = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.screen`
    background-image: url('${p => p.src}');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  `}
`

export const HeroBackground = styled.div`
  width: 100%;
  position: relative;
`

export const HeroNoBackground = styled.div`
  width: 100%;
  position: relative;
`

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 468px;

  ${media.screen`
    width: 528px;
  `};
`


export const HeroOverlay = styled.a`
  background-image: url('${p => p.src}');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;


  ${media.screen` display: none; `};
`

import React from 'react'
import ListPanel from 'pages/homepage/ListPanel'
import CuisineGrid from 'components.v2/CuisineGrid'
import withContainer from './CuisinesSectionContainer'

export default withContainer(({ cuisines }) => (
  <ListPanel title='Popular cuisines' link={'/cuisines'}>
    <CuisineGrid cuisines={cuisines} className='xl:pt-5' />
  </ListPanel>
))

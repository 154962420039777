import { compose, withProps, setDisplayName, lifecycle } from 'recompose'
import qs from 'qs'
import isNode from 'detect-node'
import { connect } from 'react-redux'

import { changeCuisineInput } from 'common/CuisineSearchBar/cuisine'
import { withAnalyticsHandler } from 'enhancers/withAnalytics'
import * as city from 'store/homepage/city'
import cities from 'const/cities'
import events from 'const/analytics-events'
import getHistory from 'services/history'
import get from 'lodash/get'

const withCityReduxState = connect(
  state => ({
    title: `Best takeaway in ${city.selectCity(state).name}`,
    city: city.selectCity(state),
    venues: city.selectVenues(state),
  }),
  { setCity: city.set, changeCuisineInput },
)

const withGoToCity = withProps(({ city, changeCuisineInput, trackEvent }) => ({
  action: () => {
    const query = qs.stringify({ ...city.geo, locationName: city.name })

    const history = getHistory()

    changeCuisineInput('')

    trackEvent(events['POPULAR_CLICK_VIEWALL'])

    history.push(`/search?${query}`)
  },
}))

export default compose(
  withAnalyticsHandler,
  withCityReduxState,
  withGoToCity,
  withProps({
    listType: 'POPULAR',
  }),

  lifecycle({
    bootstrap() {
      return this.props.setCity(cities[0])
    },

    componentDidMount() {
      if (!isNode && !get(this, 'props.venues', []).length) this.bootstrap()
    },
  }),

  setDisplayName('CityVenuesSectionContainer'),
)

import React from 'react'

import {
  HeroSectionWrapper,
  HeroBackground,
  HeroContent,
  HeroOverlay,
  HeroNoBackground,
} from './styled'

import DesktopContainer from 'layouts/DesktopContainer'
import { MobileScreen, DesktopScreen } from 'utils/screen'

const HeroContents = ({ children }) => {
  return (
    <HeroContent>
        {children}
    </HeroContent>
  )
}

const HeroMobile = ({src, action}) => {
  return (
    <MobileScreen>
      <HeroNoBackground>
        <HeroContents>
          <HeroOverlay src={src} href={action}/>
        </HeroContents>
      </HeroNoBackground>
    </MobileScreen>
  )
}

const HeroDesktop = () => {
  return (
    <DesktopScreen>
      <HeroBackground>
        <HeroContents/>
      </HeroBackground>
    </DesktopScreen>
  )
}

class MainSlide extends React.Component {
  render() {
    const {sourceM, sourceD, action} = this.props
    return (
      <HeroSectionWrapper src={sourceD} href={action}>
        <DesktopContainer>
          <HeroMobile src={sourceM} action={action} />
          <HeroDesktop />
        </DesktopContainer>
      </HeroSectionWrapper>
    )
  }
}

export default MainSlide
